import { Controller } from "@hotwired/stimulus";
import IMask from "imask";

export default class extends Controller {
  static values = { pattern: String };

  connect() {
    console.log(this.patternValue);
    this.mask = IMask(this.element, { mask: this.patternValue, lazy: false });
  }

  disconnect() {
    this.mask?.destroy();
  }
}
