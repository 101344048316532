// app/javascript/controllers/dialog_controller.js
import { Controller } from "@hotwired/stimulus";
import { Modal } from "flowbite";

// Connects to data-controller="dialog"
export default class extends Controller {
  constructor(element) {
    super(element);
    const options = {
      placement: "bottom-right",
      backdrop: "dynamic",
      backdropClasses: "bg-gray-900/50 fixed inset-0 z-40",
      closable: true,
      onHide: () => {
        console.log("modal is hidden");
      },
      onShow: () => {
        console.log("modal is shown");
      },
      onToggle: () => {
        console.log("modal has been toggled");
      },
    };

    // instance options object
    const instanceOptions = {
      id: "modalEl",
      override: true,
    };

    //this.open();
    // needed because ESC key does not trigger close event
    //this.element.addEventListener("close", this.enableBodyScroll.bind(this));
    this.modal = new Modal(this.element, options, instanceOptions);
  }

  connect() {
    // options with default values
    
    this.modal.show()
  }

  disconnect() {
    this.modal.hide()
    //this.element.removeEventListener("close", this.enableBodyScroll.bind(this));
  }

  // hide modal on successful form submission
  // data-action="turbo:submit-end->turbo-modal#submitEnd"
  submitEnd(e) {
    console.log(e)
    if (e.detail.success) {
      // this.close();
    }
  }

  open() {
    this.modal.show();
    //document.body.classList.add("overflow-hidden");
  }

  close() {
    this.modal.hide();
    // clean up modal content
    const frame = document.getElementById("modal");
    frame.removeAttribute("src");
    frame.innerHTML = "";
  }

  enableBodyScroll() {
    document.body.classList.remove("overflow-hidden");
  }

  clickOutside(event) {
    if (event.target === this.element) {
      this.close();
    }
  }
}
